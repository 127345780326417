<template>
  <div class="body">
    <div class="container">
      <nav class="topbar" v-if="!getToken">
        <div class="brand">
          <router-link to="/" class="brand-icon">
            <img src="@/assets/Logo@2x.png" class="brand-logo" />
          </router-link>
        </div>
      </nav>
      <div id="texte_a_afficher" class="texte_inselectionnable">
        <p style="text-align: center; font-size: 22px">
          <strong
            >BATIGO accompagne les artisans du bâtiment dans la <br />
            gestion au quotidien</strong
          >
        </p>
        <p>
          <span style="font-weight: bold; font-size: 18px"
            ><strong>Ce que nous faisons</strong></span
          >
        </p>
        <p>
          BATIGO est leader dans les applications mobiles multilingues auprès
          des entreprises du monde l’artisanat.<br />
        </p>
        <p>
          La vocation du groupe BATIGO est de simplifier la vie des artisans qui
          sont très souvent débordés de rendez-vous de chantier et ne trouvent
          plus le temps de réaliser le service nécessaire auprès de ses clients,
          que ce soit un service commercial ou administratif.
          <br />
          Les 2 objectifs de BATIGO sont donc de
        </p>
        <dl>
          <dd style="margin-left: 32px">
            <span>- </span>
            Simplifier et automatiser certaines actions commerciales (création
            de devis, relance client par exemple),
          </dd>
          <dd style="margin-left: 32px">
            <span>- </span>
            Simplifier l’usage des outils informatiques tels qu’une application
            mobile dédiée, multilingue, intégrant une expérience utilisateur
            aboutie afin de réaliser en temps réel les devis chez le client, les
            factures, les prises de rendez-vous par exemple.
          </dd>
        </dl>
        <p>
          Nous accompagnons l’ensemble de la corporation artisanale sur le long
          terme, apportant notre savoir-faire métier, assistance, et innovation.
        </p>
        <p>
          <span style="font-weight: bold; font-size: 18px"
            ><strong>Notre histoire</strong></span
          >
        </p>
        <p>
          En 2021, les 3 fondateurs ont décidé d’unir leur expériences et
          compétences dans leur domaines respectifs, l’artisanat, les
          technologies numériques et digitales et pour le 3ème fondateur, la
          finance et l’immobilier.
        </p>
        <p>
          Relevant les problématiques de l’artisan et le manque d’outils
          simplifiés, les fondateurs ont décidé de créer un écosystème complet
          pour accompagner les artisans au quotidien. L’aventure démarre il y a
          2 ans en constituant une équipe de développeurs chevronnés et en
          construisant la 1ère marche du projet, une application mobile,
          multilingues, réalisant des devis, des factures et toute une gestion
          des produits et prestations de l’artisan. Cette application a pour
          finalité de :
        </p>
        <dl>
          <dd style="margin-left: 32px">
            <span>- </span>
            Faire gagner du temps à l’artisan
          </dd>
          <dd style="margin-left: 32px">
            <span>- </span>
            Simplifier l’usage des outils technologiques par une utilisation
            simple et complète
          </dd>
          <dd style="margin-left: 32px">
            <span>- </span>
            Permettre aux artisans ne maitrisant pas la langue française, de
            pouvoir réaliser des devis et des factures notamment,
            automatiquement en français alors qu’ils utilisent l’application
            dans leur langue natale par exemple.
          </dd>
        </dl>
        <p>
          Aujourd’hui la communauté des artisans dans la construction représente
          environs 500 000 entreprises en France dont 50% sont des entreprises
          individuelles qui doivent gérer l’ensemble des services de leur
          entreprise seul. <br />
          BATIGO est la solution !
        </p>
        <p>
          <span style="font-weight: bold; font-size: 18px"
            ><strong>Notre solution</strong></span
          >
        </p>
        <p>
          Notre solution, qu’elle soit sur application mobile ou ordinateur,
          gère la gestion commerciale, la création de devis, la facturation, la
          gestion de planning dans la langue souhaitée de l’artisan.<br />
        </p>
        <p>
          Grâce à son application mobile dédiée au métier de l’artisan du
          bâtiment, BATIGO soutient les artisans que ce soit chez ses clients,
          ou au bureau, offrant ainsi un gain de temps et une solution adaptée à
          100% à la mobilité permanente de l’artisan.<br />
        </p>
        <p>
          L’artisan peut désormais se concentrer sur son métier, maîtriser son
          développement commercial et piloter la croissance de son
          entreprise.<br />
        </p>
      </div>
    </div>
    <!-- <footer>
      <div class="link">
        <router-link to="/about_us">{{ $t("ABOUT_US") }}</router-link>
        <router-link to="/privacy">{{ $t("LEGAL_MENTION") }}</router-link>
        <router-link to="/cgu">{{ $t("C.G.U") }}</router-link>
      </div>
      <p>{{ $t("COPYRIGHT") }}</p>
    </footer> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      lang: 0,
    };
  },

  methods: {
    ...mapActions(["changeLang"]),

    chooseLang(lang) {
      this.changeLang(lang);
    },

    close(e) {
      if (this.$refs["langue"])
        if (!this.$refs["langue"].contains(e.target)) {
          this.lang = 0;
        }
    },
  },

  computed: {
    ...mapGetters([
      "getTabs",
      "getToken",
      "getSelectedLang",
      "getAllLangue",
      "getActiveLangue",
    ]),
  },

  mounted() {
    document.addEventListener("click", this.close);
  },

  beforeDestroy() {
    document.removeEventListener("click", this.close);
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 15px;
  margin-top: 55px;
  @media only screen and (max-width: 900px) {
    padding: 20px;
  }
}

.body {
  display: block;
  width: 100%;
}

footer {
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
  border-top: 13px solid #e4261b;
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  background-color: #28367a;
  height: 92px;
  color: #fff;
  .link {
    @media only screen and (max-width: 900px) {
      width: 100%;
      justify-content: space-around;
    }
    width: 35%;
    display: flex;
    justify-content: space-between;

    & a {
      text-decoration: underline;
      color: #fff;

      &:hover {
        color: #ffffff80;
      }
    }
  }

  & p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
  }
}
</style>
